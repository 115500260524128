<template>
  <div class="r-container" :class="{'is-loading': isLoading}">
    <view-loading :title="loadingTitle || null" v-if="isLoading"></view-loading>
    <transition name="slide">
      <div v-if="!isLoading" class="content-transition print-view-print" id="container-printer">


        <div v-if="title" class="hidden-print">
          <h2 class="text-center text-uppercase font-bold m-b-none">{{title}}</h2>
          <h4 class="text-center text-uppercase">{{subtitle}}</h4>
        </div>
        <div class="printer-page pos-rlt">
          <div v-if="title" class="visible-print">
            <h2 class="text-center text-uppercase font-bold m-b-none">{{ title }}</h2>
            <h4 class="text-center text-uppercase">{{ subtitle }}</h4>
          </div>
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import PrintDefault from '@/components/print/defaults/PrintDefault'

export default {
  name: "PrintFolhaA4",
  mixins: [PrintDefault],
  data() {
    return {}
  }
}
</script>
