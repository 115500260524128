<script>
import Print from './LeilaoPrintPage'
import {relatorioLancesLeilaoPorComitente} from "@/domain/leiloes/services"

export default {
  mixins: [Print],
  components: {},
  nome: 'LeilaoRelatoriosLancesPorComitente',
  data() {
    return {
      dados: null
    }
  },
  computed: {},
  mounted() {
  },
  activated() {
  },
  created() {
    this.isLoading = true
    relatorioLancesLeilaoPorComitente(this.leilao.id)
        .then((response) => {
          this.dados = response.data
          this.isLoading = false
        })
        .catch((response) => {
          // this.isLoading = false
          this.alertApiError(response)
          console.log(response)
        })
  },
  deactivated() {
  },
  watch: {},
  methods: {}
}
</script>

<template>
  <print-relatorio :is-loading="isLoading">
    <div v-if="!isLoading" class="r-table print-view-folha-a4">
      <div v-if="dados && dados.template" v-html="dados.template.template"></div>

      <div v-else style="padding: 50px">
        {{dados}}
      </div>
    </div>
    <!--<div class="p-page-numer visible-print">Página {{key+1}} de {{filteredData && filteredData.length}}</div>-->
  </print-relatorio>
</template>
