<script>
import { VueMaskDirective as mask } from 'v-mask'
import PrintRelatorio from "@/components/print/defaults/PrintRelatorio"

export default {
  components: {PrintRelatorio},
  nome: 'LeilaoPrintPage',
  props: [
      'title',
      'leilao'
  ],
  directives: {mask},
  data () {
    return {
      isLoading: false,
      template: null
    }
  },
  computed: {
  },
  mounted () {
  },
  activated () {
  },
  created () {
  },
  deactivated () {
  },
  watch: {},
  methods: {
  }
}
</script>
